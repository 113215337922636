
import { StoreActionTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";

const SetProjectIntegrationProps = Vue.extend({
  props: {
    projectId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
    integrationId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
  },
});

@Component
export default class SetProjectIntegration extends SetProjectIntegrationProps {
  private componentClass = "SetProjectIntegration";

  async mounted() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT,
      this.projectId
    );
    this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_ID,
      this.integrationId
    );

    this.$router.push({ name: "projectCreateStepThree" });
  }
}
