
import {
  BaseEntityModel,
  CheckListModel,
  CheckListWithCategoriesSimpleModel,
  ProjectModel,
  UpdateProjectCheckListsRequestModel,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";
import ProjectDetailsHeader from "./header/ProjectDetailsHeader.vue";
import ProjectCheckListGridComponent from "@/components/grids/projectDetails/checkLists/ProjectCheckListGridComponent.vue";
import ProjectIntegrationsGridComponent from "@/components/grids/projectDetails/integrations/ProjectIntegrationsGridComponent.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";

import SelectCheckListMultiple from "@/components/select/checkLists/SelectCheckListMultiple.vue";
import ProjectToasts from "@/components/project/toasts/ProjectToasts.vue";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import { getStore } from "@/store/getStore";
import { ProjectType } from "@/services/types/ProjectType";
import { isUserCanManageProjects } from "@/services/roles/helpers";

const ProjectDetailsProps = Vue.extend({
  props: {
    projectId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
  },
});

@Component({
  methods: { isUserCanManageProjects },
  components: {
    ProjectDetailsHeader,
    ProjectCheckListGridComponent,
    ProjectIntegrationsGridComponent,
    ActionButton,
    ModalComponent,
    SelectCheckListMultiple,
    ProjectToasts,
  },
})
export default class ProjectDetails extends ProjectDetailsProps {
  private componentClass = "ProjectDetails";

  private selectedCheckLists: BaseEntityModel[] = [];
  private checkListItems: CheckListWithCategoriesSimpleModel[] = [];
  private showEditCheckListForm = false;

  get showIntegrations() {
    const projectType = getStore().getters.getProjectsSelectedProject.type;
    return projectType != ProjectType.CARD && projectType != ProjectType.LEAD;
  }

  async mounted() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT,
      this.projectId
    );
    await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIMITS);
  }

  get loadData(): boolean {
    return (
      (this.$store.getters.getProjectsSelectedProject as ProjectModel) == null
    );
  }

  handleAddIntegrationbBtnClick() {
    const projectId = (
      this.$store.getters.getProjectsSelectedProject as ProjectModel
    ).id;
    this.$router.push(`/projects/${projectId}/add-integration`);
  }

  get addIntegrationbBtnStatus(): boolean {
    const limits = this.$store.getters.getProjectsLimits;
    if (!limits) {
      return true;
    }

    if (!limits.integrations.max) {
      return false;
    }

    return limits.integrations.totalActive >= limits.integrations.max;
  }

  get listItems(): CheckListModel[] {
    return this.$store.getters.getProjectsSelectedProjectCheckList;
  }

  handleSelectChange(selectedItems: BaseEntityModel[]) {
    this.selectedCheckLists = selectedItems;
    this.setCheckListItems();
  }

  toastDeleteListener(id: number) {
    const list: BaseEntityModel[] = this.selectedCheckLists.filter(
      (item) => item.id != id
    );
    this.selectedCheckLists = [...list];
    this.setCheckListItems();
  }

  setCheckListItems() {
    const list: CheckListWithCategoriesSimpleModel[] = [];
    const fullList = this.$store.getters
      .getCheckListsListSimple as CheckListWithCategoriesSimpleModel[];

    this.selectedCheckLists
      .map((item) => item.id)
      .map((id) => {
        if (fullList.findIndex((item) => item.id == id) >= 0) {
          list.push(fullList.find((item) => item.id == id));
        }
      });

    this.checkListItems = [...list];
  }

  async handleEditCheckListFormShow() {
    this.selectedCheckLists = this.listItems;

    this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_CLEAR
    );
    await this.$store.dispatch(StoreActionTypes.CHECKLISTS_LIST_SIMPLE);
    this.setCheckListItems();

    this.showEditCheckListForm = !this.showEditCheckListForm;
  }

  async handleEditCheckListModalOkBtnClick() {
    const request: UpdateProjectCheckListsRequestModel = {
      ids: this.selectedCheckLists.map((item) => item.id),
    };

    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT_UPDATE_CHECKLIST,
      request
    );
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT_CHECKLIST_GET
    );

    this.showEditCheckListForm = !this.showEditCheckListForm;
  }

  get editCheckListFormDisabled(): boolean {
    return this.$store.getters.isProjectsDataLoading;
  }
}
