
import { StoreActionTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";
import CriterionReportsHeader from "./header/CriterionReportsHeader.vue";
import { i18n } from "@/i18n";
import GridFilter from "@/components/grids/reports/filter/GridFilter.vue";
import GridComponent from "@/components/grids/reports/list/GridComponent.vue";
import HorizontalBarChart from "@/components/charts/HorizontalBarChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
import {
  BaseReportRenderModel,
  ChartTypesListItem,
  ReportCallsListFilterBaseModel,
  ReportsChartType,
  ReportsListDateFilterModel,
} from "@/services/types";
import ReportCallsListGridComponent from "@/components/grids/reports/callList/ReportCallsListGridComponent.vue";
import BarChart from "@/components/charts/BarChart.vue";

const CriterionReportProps = Vue.extend({
  props: {
    criterionId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
  },
});

@Component({
  components: {
    CriterionReportsHeader,
    GridFilter,
    GridComponent,
    HorizontalBarChart,
    LineChart,
    ReportCallsListGridComponent,
    BarChart,
  },
  watch: {
    "$store.getters.getReportsRenderList": "handleChangeReportsRenderList",
  },
})
export default class CriterionReport extends CriterionReportProps {
  private componentClass = "CriterionReport";

  private chartType: ReportsChartType = "bar";
  private chartTypesList: ChartTypesListItem[] = [
    {
      label: i18n.t("reports.charts.type.linear") as string,
      value: "line",
      state: false,
    },
    {
      label: i18n.t("reports.charts.type.bar") as string,
      value: "bar",
      state: true,
    },
    {
      label: i18n.t("reports.charts.type.horizontal") as string,
      value: "horizontal",
      state: false,
    },
  ];

  private selectedManagerId = null;

  mounted() {
    this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_SELECTED_CRITERION,
      this.criterionId
    );
  }

  onChartTypeSwitherClick(val: ReportsChartType) {
    this.chartType = val;
    this.chartTypesList.map((item) => {
      item.value === this.chartType
        ? (item.state = true)
        : (item.state = false);
    });
  }

  get chartLineData() {
    return {
      labels: this.$store.getters.getReportsLineChartsLabels,
      datasets: this.$store.getters.getReportsLineChartsList,
    };
  }

  get chartBarData() {
    return {
      labels: this.$store.getters.getReportsBarChartsLabels,
      datasets: this.$store.getters.getReportsBarChartsList,
    };
  }

  async handleDateFilterChange(request: ReportsListDateFilterModel) {
    await this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_LIST_FILTER_SELECTED_CRITERION,
      request
    );
  }

  get gridReportTitle() {
    return i18n.t("reports.grid.managers.name");
  }

  get getCallListTitle() {
    if (this.hasSelectedManagerId) {
      const description = i18n.t("reports.calls.criterion.title");
      const name = (
        this.$store.getters.getReportsRenderList as BaseReportRenderModel[]
      ).find((item) => item.id == this.selectedManagerId).report.name;
      return `${description} - ${name}`;
    } else {
      return "";
    }
  }

  async rowClickListener(id: number) {
    this.selectedManagerId = id;
    await this.getCallsList();
  }

  get hasSelectedManagerId(): boolean {
    return this.selectedManagerId;
  }

  async getCallsList() {
    const request: ReportCallsListFilterBaseModel = {
      managerId: this.selectedManagerId,
      criterionId: parseInt(this.criterionId),
    };
    await this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_CALLS_LIST,
      request
    );
  }

  get loadData(): boolean {
    return (
      (this.$store.getters.getReportsRenderList as BaseReportRenderModel[])
        .length == 0
    );
  }

  async handleChangeReportsRenderList() {
    await this.getCallsListFilterChange();
  }

  async getCallsListFilterChange() {
    if (this.hasSelectedManagerId) {
      const list: BaseReportRenderModel[] =
        this.$store.getters.getReportsRenderList;
      if (list.findIndex((item) => item.id == this.selectedManagerId) >= 0) {
        await this.getCallsList();
      } else {
        this.selectedManagerId = null;
      }
    }
  }
}
