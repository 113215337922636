
import Vue from "vue";
import Component from "vue-class-component";
import {
  faArrowLeft,
  faPhone,
  faAddressCard,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { ProjectModel } from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import ConfirmationComponent from "@/components/modal/ConfirmationComponent.vue";
import ModalComponent from "@/components/modal/ModalComponent.vue";
import FormInput from "@/components/lib/input/FormInput.vue";
import { getStore } from "@/store/getStore";
import { ProjectType } from "@/services/types/ProjectType";
import { isUserCanManageProjects } from "@/services/roles/helpers";

@Component({
  methods: { isUserCanManageProjects },
  components: {
    ActionButton,
    ConfirmationComponent,
    ModalComponent,
    FormInput,
  },
  watch: {
    "$store.getters.getProjectsSelectedProject": "handleSelectedProjectChange",
  },
})
export default class ProjectDetailsHeader extends Vue {
  private componentClass = "ProjectDetailsHeader";
  private back: IconDefinition = faArrowLeft;
  private deleteProjectConfirmationShow = false;

  private showChangeProjectNameForm = false;

  private editedProjectName = "";

  get getIcon() {
    switch (getStore().getters.getProjectsSelectedProject.type) {
      case ProjectType.CALL:
        return faPhone;
      case ProjectType.CARD:
        return faAddressCard;
      default:
        return undefined;
    }
  }

  mounted() {
    this.editedProjectName = this.projectName;
  }

  get projectName() {
    return (this.$store.getters.getProjectsSelectedProject as ProjectModel)
      ? (this.$store.getters.getProjectsSelectedProject as ProjectModel).name
      : "";
  }

  get disabled() {
    return this.$store.getters.isProjectsDataLoading;
  }

  handleBackIconClick() {
    this.$store.dispatch(StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT_CLEAR);
    this.$router.push("/projects");
  }

  handleProjectSttSettingsBtnClick() {
    const id = (this.$store.getters.getProjectsSelectedProject as ProjectModel)?.id || null
    if (id !== null) {
      this.$router.push({
        name: "projectSttSettings",
        params: {
          projectId: id.toString()
        }
      });
    }
  }

  handleDeleteProjectBtnClick() {
    this.deleteProjectConfirmationShow = true;
  }

  deleteProjectCancel() {
    this.deleteProjectConfirmationShow = false;
  }

  async deleteProject() {
    await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_PROJECT_DELETE, {
      ids: [
        (this.$store.getters.getProjectsSelectedProject as ProjectModel)
          ? (this.$store.getters.getProjectsSelectedProject as ProjectModel).id
          : null,
      ],
    });

    this.deleteProjectCancel();
    this.handleBackIconClick();
  }

  handleEditProjectNameBtnClick() {
    this.showChangeProjectNameForm = !this.showChangeProjectNameForm;
  }

  get modalChangeProjectNameFormDisabled() {
    return this.disabled || this.editedProjectName.length < 3;
  }

  projectNameChange(text: string) {
    this.editedProjectName = text;
  }

  async handleModalChangeProjectNameFormOkBtnClick() {
    await this.editProjectName();
    this.handleEditProjectNameBtnClick();
  }

  async editProjectName() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_PROJECT_CHANGE_NAME,
      this.editedProjectName
    );
  }

  handleSelectedProjectChange(project: ProjectModel) {
    this.editedProjectName = project.name;
  }
}
