
import Vue from "vue";
import Component from "vue-class-component";
import { IconDefinition, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { StoreActionTypes } from "@/store/types";
import { BaseEntityModel } from "@/services/types";

@Component
export default class CriterionReportsHeader extends Vue {
  private componentClass = "CriterionReportsHeader";
  private back: IconDefinition = faArrowLeft;

  get criterionName() {
    return this.$store.getters.getReportsSelectedCriterion
      ? (this.$store.getters.getReportsSelectedCriterion as BaseEntityModel)
          .name
      : "";
  }

  handleBackIconClick() {
    this.$store.dispatch(StoreActionTypes.SET_REPORTS_LIST_CLEAR);
    this.$router.push("/");
  }
}
