
import Vue from "vue";
import Component from "vue-class-component";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { BaseEntityModel } from "@/services/types";
import ProjectCreateHeader from "@/views/projects/create/header/ProjectCreateHeader.vue";
import { i18n } from "@/i18n/index";
import { StoreActionTypes } from "@/store/types";

require("@/assets/images/amocrm-logo.png");
require("@/assets/images/bitrix24-logo.png");

const AddProjectIntegrationProps = Vue.extend({
  props: {
    projectId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
  },
});

@Component({
  components: {
    FormInput,
    ActionButton,
    ProjectCreateHeader,
  },
})
export default class AddProjectIntegration extends AddProjectIntegrationProps {
  private componentClass = "AddProjectIntegration";
  private webhookHelp =
    "https://b24-2e2b9v.bitrix24.ua/devops/section/standard/";
  private webhookURL = "";
  private webhookInputActive = false;
  private webhookHelpShow = false;

  async mounted() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT,
      this.projectId
    );
  }

  handleAmoBtnClick() {
    window.location.href = this.createAmoCrmLink();
  }

  handleBitrixBtnClick() {
    this.webhookInputActive = !this.webhookInputActive;
  }

  async handleBitrixNext() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_BITRIX_ADD,
      this.webhookURL
    );
  }

  createAmoCrmLink() {
    const params = new URLSearchParams({
      state: this.projectId.toString(),
      name: this.projectName(),
      description: this.projectDesc(),
    });

    const redirectUri = process.env.VUE_APP_AMO_REDIRECT_URI
      ? process.env.VUE_APP_AMO_REDIRECT_URI
      : "";
    const secretsUri = process.env.VUE_APP_AMO_SECRETS_URI
      ? process.env.VUE_APP_AMO_SECRETS_URI
      : "";
    const logo = process.env.VUE_APP_AMO_LOGO
      ? process.env.VUE_APP_AMO_LOGO
      : "";

    return `https://www.amocrm.ru/oauth/?${params.toString()}&redirect_uri=${redirectUri}&secrets_uri=${secretsUri}&logo=${logo}&scopes[]=crm&scopes[]=notifications`;
  }

  projectName(): string {
    return this.$store.getters.getProjectsSelectedProject
      ? `${i18n.t("projects.amo.name")} ${
          (this.$store.getters.getProjectsSelectedProject as BaseEntityModel)
            .name
        }`
      : (i18n.t("projects.amo.name") as string);
  }

  projectDesc(): string {
    return `${i18n.t("projects.amo.description")} ${this.projectName()}`;
  }

  webhookURLChange(value: string) {
    this.webhookURL = value;
  }
}
