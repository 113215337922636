import { render, staticRenderFns } from "./ProjectCreateStepThree.vue?vue&type=template&id=5389bcb1&scoped=true&"
import script from "./ProjectCreateStepThree.vue?vue&type=script&lang=ts&"
export * from "./ProjectCreateStepThree.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectCreateStepThree.vue?vue&type=style&index=0&id=5389bcb1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5389bcb1",
  null
  
)

export default component.exports