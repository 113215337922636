
import { StoreActionTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";
import SelectCheckListMultiple from "@/components/select/checkLists/SelectCheckListMultiple.vue";
import {
  BaseEntityModel,
  CheckListWithCategoriesSimpleModel,
  CreateNewProjectRequestModel,
} from "@/services/types";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import ProjectCreateHeader from "@/views/projects/create/header/ProjectCreateHeader.vue";
import ProjectToasts from "@/components/project/toasts/ProjectToasts.vue";
import { ProjectType } from "@/services/types/ProjectType";
import DefaultSelect from "@/components/lib/select/DefaultSelect.vue";

@Component({
  components: {
    SelectCheckListMultiple,
    FormInput,
    ActionButton,
    ProjectCreateHeader,
    ProjectToasts,
    DefaultSelect,
  },
})
export default class ProjectCreateStepOne extends Vue {
  componentClass = "ProjectCreateStepOne";

  selectedItems: BaseEntityModel[] = [];
  listItems: CheckListWithCategoriesSimpleModel[] = [];
  hasSelectedItems = false;

  name = "";

  projectType: ProjectType = ProjectType.CALL;

  get projectTypesOptions() {
    return [
      {
        value: ProjectType.CALL,
        text: "Звонки",
      },
      {
        value: ProjectType.CARD,
        text: "Карточки",
      },
      {
        value: ProjectType.LEAD,
        text: "Лиды",
      },
    ];
  }

  async mounted() {
    this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_CLEAR
    );
    await this.$store.dispatch(StoreActionTypes.CHECKLISTS_LIST_SIMPLE);
    this.setListItems();
    this.checkQueryParams();
  }

  handleSelectChange(selectedItems: BaseEntityModel[]) {
    this.selectedItems = selectedItems;
    this.hasSelectedItems = true;
    this.setListItems();
  }

  setListItems() {
    const list: CheckListWithCategoriesSimpleModel[] = [];
    const fullList = this.$store.getters
      .getCheckListsListSimple as CheckListWithCategoriesSimpleModel[];
    this.selectedItems
      .map((item) => item.id)
      .map((id) => {
        if (fullList.findIndex((item) => item.id == id) >= 0) {
          list.push(fullList.find((item) => item.id == id));
        }
      });

    this.listItems = [...list];
  }

  onNameChange(name: string) {
    this.name = name;
  }

  async handleCreateNewProjectBtnClick() {
    const model: CreateNewProjectRequestModel = {
      name: this.name,
      type: this.projectType,
      ids: this.selectedItems.map((item) => item.id),
    };
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_PROJECT_CREATE,
      model
    );

    switch (this.projectType) {
      case ProjectType.LEAD:
      case ProjectType.CARD:
        await this.$router.push({
          name: "Projects",
        });
        return;
      default:
        this.$router.push({ name: "projectCreateStepTwo" });
        break;
    }
  }

  toastDeleteListener(id: number) {
    const list: BaseEntityModel[] = this.selectedItems.filter(
      (item) => item.id != id
    );
    this.selectedItems = [...list];
    this.hasSelectedItems = false;
    this.setListItems();
  }

  get createNewProjectBtnDisabled(): boolean {
    let disabled = true;

    if (this.name.length >= 5) {
      disabled = !this.hasSelectedItems;
    }

    return disabled;
  }

  checkQueryParams() {
    if (
      this.$route.query.checkListId &&
      parseInt(this.$route.query.checkListId as string)
    ) {
      const selectedItem: BaseEntityModel = this.getCheckListItemList.find(
        (item) => item.id == parseInt(this.$route.query.checkListId as string)
      );
      if (selectedItem) {
        this.handleSelectChange([selectedItem]);
      }
    }
  }

  get getCheckListItemList(): BaseEntityModel[] {
    return this.$store.getters.getCheckListsListSimpleOnlyCheckLists;
  }
}
