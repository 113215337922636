
import { StoreActionTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";
import ManagerReportsHeader from "./header/ManagerReportsHeader.vue";
import { i18n } from "@/i18n";
import GridFilter from "@/components/grids/reports/filter/GridFilter.vue";
import GridComponent from "@/components/grids/reports/list/GridComponent.vue";
import HorizontalBarChart from "@/components/charts/HorizontalBarChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
import {
  BaseReportRenderModel,
  ChartTypesListItem,
  ReportCallsListFilterBaseModel,
  ReportsChartType,
  ReportsListDateFilterModel,
} from "@/services/types";
import ReportCallsListGridComponent from "@/components/grids/reports/callList/ReportCallsListGridComponent.vue";
import BarChart from "@/components/charts/BarChart.vue";

const ManagerReportProps = Vue.extend({
  props: {
    managerId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
  },
});

@Component({
  components: {
    ManagerReportsHeader,
    GridFilter,
    GridComponent,
    HorizontalBarChart,
    LineChart,
    ReportCallsListGridComponent,
    BarChart,
  },
  watch: {
    "$store.getters.getReportsRenderList": "handleChangeReportsRenderList",
  },
})
export default class ManagerReport extends ManagerReportProps {
  private componentClass = "ManagerReport";

  private chartType: ReportsChartType = "bar";
  private chartTypesList: ChartTypesListItem[] = [
    {
      label: i18n.t("reports.charts.type.linear") as string,
      value: "line",
      state: false,
    },
    {
      label: i18n.t("reports.charts.type.bar") as string,
      value: "bar",
      state: true,
    },
    {
      label: i18n.t("reports.charts.type.horizontal") as string,
      value: "horizontal",
      state: false,
    },
  ];

  private selectedCriterionId = null;

  async mounted() {
    if (!this.managerId) {
      this.managerId = this.$store.getters.getReportsSelectedManager;
    }
    this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_SELECTED_MANAGER,
      this.managerId
    );
  }

  onChartTypeSwitherClick(val: ReportsChartType) {
    this.chartType = val;
    this.chartTypesList.map((item) => {
      item.value === this.chartType
        ? (item.state = true)
        : (item.state = false);
    });
  }

  get chartLineData() {
    return {
      labels: this.$store.getters.getReportsLineChartsLabels,
      datasets: this.$store.getters.getReportsLineChartsList,
    };
  }

  get chartBarData() {
    return {
      labels: this.$store.getters.getReportsBarChartsLabels,
      datasets: this.$store.getters.getReportsBarChartsList,
    };
  }

  get currentFilters() {
    return this.$store.getters.getReportsFilterValue;
  }

  async handleDateFilterChange(request: ReportsListDateFilterModel) {
    await this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_LIST_FILTER_SELECTED_MANAGER,
      request
    );
  }

  get gridReportTitle() {
    return i18n.t("reports.grid.criterions.name");
  }

  get getCallListTitle() {
    if (this.hasSelectedCriterionId) {
      const description = i18n.t("reports.calls.manager.title");
      const name = (
        this.$store.getters.getReportsRenderList as BaseReportRenderModel[]
      ).find((item) => item.id == this.selectedCriterionId).report.name;
      return `${description} - ${name}`;
    } else {
      return "";
    }
  }

  async rowClickListener(id: number) {
    this.selectedCriterionId = id;
    await this.getCallsList();
  }

  get hasSelectedCriterionId(): boolean {
    return this.selectedCriterionId;
  }

  async getCallsList() {
    const request: ReportCallsListFilterBaseModel = {
      managerId: parseInt(this.managerId),
      criterionId: this.selectedCriterionId,
    };
    await this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_CALLS_LIST,
      request
    );
  }

  async handleChangeReportsRenderList() {
    await this.getCallsListFilterChange();
  }

  async getCallsListFilterChange() {
    if (this.hasSelectedCriterionId) {
      const list: BaseReportRenderModel[] =
        this.$store.getters.getReportsRenderList;
      if (list.findIndex((item) => item.id == this.selectedCriterionId) >= 0) {
        await this.getCallsList();
      } else {
        this.selectedCriterionId = null;
      }
    }
  }

  get loadData(): boolean {
    return this.$store.getters.isReportsDataLoading;
    // return (this.$store.getters.getReportsRenderList as BaseReportRenderModel []).length == 0
  }
}
