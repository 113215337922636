import Vue from "vue";

export const IProjectCreateHeader = Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    progress: {
      type: Number,
      required: false,
      default: null,
    },
  },
});
