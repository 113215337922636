
import Component from "vue-class-component";
import { IProjectCreateHeader } from "./IProjectCreateHeader";

@Component
export default class ProjectCreateHeader extends IProjectCreateHeader {
  private componentClass = "ProjectCreateHeader";

  getActiveClass(item: number): string {
    return item <= this.progress ? "active" : "";
  }

  get showProgress(): boolean {
    return !!this.progress;
  }
}
