
import Vue from "vue";
import Component from "vue-class-component";
import {
  ChartReportItemModel,
  ChartTypesListItem,
  ReportsChartType,
  ReportsListDateFilterModel,
  ReportsPagesListItem,
  ReportsPageType,
} from "@/services/types";
import { i18n } from "@/i18n";
import GridFilter from "@/components/grids/reports/filter/GridFilter.vue";
import { StoreActionTypes } from "@/store/types";
import GridComponent from "@/components/grids/reports/list/GridComponent.vue";
import HorizontalBarChart from "@/components/charts/HorizontalBarChart.vue";
import LineChart from "@/components/charts/LineChart.vue";
import EmptyDataMessage from "@/components/emptyData/EmptyDataMessage.vue";
import BarChart from "@/components/charts/BarChart.vue";

@Component({
  components: {
    GridFilter,
    GridComponent,
    HorizontalBarChart,
    LineChart,
    BarChart,
    EmptyDataMessage,
  },
})
export default class Reports extends Vue {
  private componentClass = "Reports";
  private page: ReportsPageType = "managers";
  private reportsPagesList: ReportsPagesListItem[] = [
    {
      label: i18n.t("reports.switcher.managers") as string,
      value: "managers",
      state: true,
    },
    {
      label: i18n.t("reports.switcher.criterions") as string,
      value: "criterions",
      state: false,
    },
  ];

  private chartType: ReportsChartType = "bar";
  private chartTypesList: ChartTypesListItem[] = [
    {
      label: i18n.t("reports.charts.type.linear") as string,
      value: "line",
      state: false,
    },
    {
      label: i18n.t("reports.charts.type.bar") as string,
      value: "bar",
      state: true,
    },
    {
      label: i18n.t("reports.charts.type.horizontal") as string,
      value: "horizontal",
      state: false,
    },
  ];

  async mounted() {
    this.page = this.$store.getters.getReportsPage;
    await this.onPageSwitcherClick(this.page);
  }

  async onPageSwitcherClick(val: ReportsPageType) {
    this.page = val;
    this.reportsPagesList.map((item) => {
      item.value === this.page ? (item.state = true) : (item.state = false);
    });
    await this.$store.dispatch(StoreActionTypes.SET_REPORTS_PAGE, this.page);
  }

  onChartTypeSwitherClick(val: ReportsChartType) {
    this.chartType = val;
    this.chartTypesList.map((item) => {
      item.value === this.chartType
        ? (item.state = true)
        : (item.state = false);
    });
  }

  get chartLineData() {
    return {
      labels: this.$store.getters.getReportsLineChartsLabels,
      datasets: this.$store.getters
        .getReportsLineChartsList as ChartReportItemModel[],
    };
  }

  get chartBarData() {
    return {
      labels: this.$store.getters.getReportsBarChartsLabels,
      datasets: this.$store.getters
        .getReportsBarChartsList as ChartReportItemModel[],
    };
  }

  get contentTitle() {
    const page: ReportsPageType = this.$store.getters.getReportsPage;
    return page == "managers"
      ? i18n.t("reports.switcher.managers")
      : i18n.t("reports.switcher.criterions");
  }

  async handleDateFilterChange(request: ReportsListDateFilterModel) {
    await this.$store.dispatch(
      StoreActionTypes.SET_REPORTS_LIST_FILTER,
      request
    );
  }

  get savedFilters() {
    return this.$store.getters.getSavedFilters;
  }

  get gridReportTitle() {
    const page: ReportsPageType = this.$store.getters.getReportsPage;
    return page == "managers"
      ? i18n.t("reports.grid.managers.name")
      : i18n.t("reports.grid.criterions.name");
  }

  get showChartData(): boolean {
    if (this.chartType == "line") {
      return (
        this.$store.getters.getReportsLineChartsList &&
        (this.$store.getters.getReportsLineChartsList as ChartReportItemModel[])
          .length > 0
      );
    } else {
      return (
        this.$store.getters.getReportsBarChartsList &&
        (this.$store.getters.getReportsBarChartsList as ChartReportItemModel[])
          .length > 0
      );
    }
  }
}
