
import Vue from "vue";
import Component from "vue-class-component";
import { IconDefinition, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ColorTheme, ProjectIntegration, ProjectModel } from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import ConfirmationComponent from "@/components/modal/ConfirmationComponent.vue";

@Component({
  components: {
    ActionButton,
    ConfirmationComponent,
  },
})
export default class ProjectIntegrationHeader extends Vue {
  private componentClass = "ProjectIntegrationHeader";
  private back: IconDefinition = faArrowLeft;

  private deleteIntegrationConfirmationShow = false;
  private changeIntegrationStatusConfirmationShow = false;

  get projectIntegrationType() {
    return (this.$store.getters
      .getProjectIntegrationSelectedProjectIntegration as ProjectIntegration)
      ? (
          this.$store.getters
            .getProjectIntegrationSelectedProjectIntegration as ProjectIntegration
        ).type
      : "";
  }

  get projectName() {
    return (this.$store.getters.getProjectsSelectedProject as ProjectModel)
      ? (this.$store.getters.getProjectsSelectedProject as ProjectModel).name
      : "";
  }

  get disabled() {
    return this.$store.getters.isProjectsDataLoading;
  }

  handleBackIconClick() {
    const projectId = this.$store.getters.getProjectsSelectedProject.id;
    this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_DETAILS_CLEAR
    );
    this.$router.push(`/projects/${projectId}`);
  }

  get projectIntegrationStatus() {
    return (this.$store.getters
      .getProjectIntegrationSelectedProjectIntegration as ProjectIntegration)
      ? (
          this.$store.getters
            .getProjectIntegrationSelectedProjectIntegration as ProjectIntegration
        ).status
      : "";
  }

  get projectIntegrationId() {
    return (this.$store.getters
      .getProjectIntegrationSelectedProjectIntegration as ProjectIntegration)
      ? (
          this.$store.getters
            .getProjectIntegrationSelectedProjectIntegration as ProjectIntegration
        ).id
      : null;
  }

  get statusDropdownTitle() {
    return this.projectIntegrationStatus == "ACTIVE"
      ? this.$i18n.t("criterionsDetails.title.active")
      : this.$i18n.t("criterionsDetails.title.deactive");
  }

  get statusDropdownItemTitle() {
    return this.projectIntegrationStatus == "ACTIVE"
      ? this.$i18n.t("criterionsDetails.item.deactivate")
      : this.$i18n.t("criterionsDetails.item.activate");
  }

  get statusDropdownItemVariant(): ColorTheme {
    return this.projectIntegrationStatus == "ACTIVE" ? "success" : "warning";
  }

  get selectedIntegrationDisabled() {
    return this.$store.getters.isProjectsDataLoading;
  }

  handleChangeStatusItemClick() {
    this.changeIntegrationStatusConfirmationShow = true;
  }

  async changeIntegrationStatus() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_CHANGE_STATUS
    );

    this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_ID,
      this.projectIntegrationId
    );
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_FUNNELS_LIST_GET
    );

    this.changeIntegrationStatusCancel();
  }

  changeIntegrationStatusCancel() {
    this.changeIntegrationStatusConfirmationShow = false;
  }

  handleDeleteItemClick() {
    this.deleteIntegrationConfirmationShow = true;
  }

  async deleteIntegration() {
    const projectId = this.$store.getters.getProjectsSelectedProject.id;
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATIONS_DELETE,
      { ids: [this.projectIntegrationId] }
    );
    this.deleteIntegrationCancel();
    this.$router.push(`/projects/${projectId}`);
  }

  deleteIntegrationCancel() {
    this.deleteIntegrationConfirmationShow = false;
  }
}
