
import Vue from "vue";
import Component from "vue-class-component";
import GridFilter from "@/components/grids/projectsList/filter/GridFilter.vue";
import GridFooter from "@/components/grids/main/gridFooter/GridFooter.vue";
import GridComponent from "@/components/grids/projectsList/list/GridComponent.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { StoreActionTypes } from "@/store/types";
import { isUserHasPermission } from "@/services/roles";
import { UserPermissions } from "@/services/roles/permissionsEnum";

@Component({
  computed: {
    UserPermissions() {
      return UserPermissions;
    },
  },
  methods: { isUserHasPermission },
  components: {
    GridFilter,
    GridFooter,
    GridComponent,
    ActionButton,
  },
})
export default class Projects extends Vue {
  private componentClass = "ProjectsList";

  handleCreateNewProjectsBtnClick() {
    this.$router.push({ name: "projectCreateStepOne" });
  }

  get getTotal(): number {
    return this.$store.getters.getProjectsTotal;
  }

  get getCurrentPage(): number {
    return this.$store.getters.getProjectsListPage;
  }

  get perPage(): number {
    return this.$store.getters.getProjectsListPerPage;
  }

  async handlePageItemQuantitySelectChangeEvent(selected: string) {
    this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_LIST_PER_PAGE,
      parseInt(selected)
    );
    await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIST);
  }

  async handlePaginatorChangeEvent(page: number) {
    this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIST_PAGE, page);
    await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIST);
  }
}
