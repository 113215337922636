
import { SubscriptionModel } from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";
import FormInput from "@/components/lib/input/FormInput.vue";
import * as utils from "@/services/utils";
import { i18n } from "@/i18n";

@Component({
  components: {
    FormInput,
  },
})
export default class Subscription extends Vue {
  private componentClass = "Subscription";

  mounted() {
    this.setSubscription();
  }

  async setSubscription() {
    await this.$store.dispatch(StoreActionTypes.SET_SUBSCRIPTION_DATA);
  }

  get getSubscription(): SubscriptionModel {
    return this.$store.getters.getSubscriptionData;
  }

  get getPlan(): string {
    return this.getSubscription && this.getSubscription.plan
      ? this.getSubscription.plan.code
      : "";
  }

  get getPlanName(): string {
    return this.getSubscription && this.getSubscription.plan
      ? (i18n.t(
          `subscription.plans.${this.getSubscription.plan.code}`
        ) as string)
      : "";
  }

  get getPrice(): number {
    return this.getSubscription && this.getSubscription.plan
      ? this.getSubscription.plan.price
      : 0;
  }

  get startedAt(): string {
    return this.getSubscription && this.getSubscription.subscription
      ? utils.renderShortDateFromString(
          this.getSubscription.subscription.startedAt
        )
      : "";
  }

  get payedAt(): string {
    return this.getSubscription && this.getSubscription.subscription
      ? utils.renderShortDateFromString(
          this.getSubscription.subscription.payedAt
        )
      : "";
  }

  get payedTo(): string {
    return this.getSubscription && this.getSubscription.subscription
      ? utils.renderShortDateFromString(
          this.getSubscription.subscription.payedTo
        )
      : "";
  }

  get daysLeft(): number {
    return this.getSubscription && this.getSubscription.subscription
      ? this.getSubscription.subscription.daysLeft
      : 0;
  }

  get usersTotalActive(): number {
    return this.getSubscription && this.getSubscription.users
      ? this.getSubscription.users.totalActive
      : 0;
  }

  get usersMax(): number {
    return this.getSubscription && this.getSubscription.users
      ? this.getSubscription.users.max
      : 0;
  }
  get integrationsTotalActive(): number {
    return this.getSubscription && this.getSubscription.integrations
      ? this.getSubscription.integrations.totalActive
      : 0;
  }

  get integrationsMax(): number {
    return this.getSubscription && this.getSubscription.integrations
      ? this.getSubscription.integrations.max
      : 0;
  }

  get callsEstimationsTotalInPeriod(): number {
    return this.getSubscription && this.getSubscription.callsEstimations
      ? this.getSubscription.callsEstimations.totalInPeriod
      : 0;
  }

  get callsEstimationsMax(): number {
    return this.getSubscription && this.getSubscription.callsEstimations
      ? this.getSubscription.callsEstimations.max
      : 0;
  }
}
