
import Vue from "vue";
import Component from "vue-class-component";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import {
  BaseEntityModel,
  ColorTheme,
  ProjectLimitsModel,
  SubscriptionItemDataModel,
} from "@/services/types";
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";
import ProjectCreateHeader from "@/views/projects/create/header/ProjectCreateHeader.vue";
import { i18n } from "@/i18n/index";

require("@/assets/images/amocrm-logo.png");
require("@/assets/images/bitrix24-logo.png");

@Component({
  components: {
    FormInput,
    ActionButton,
    ProjectCreateHeader,
  },
})
export default class ProjectCreateStepTwo extends Vue {
  private componentClass = "ProjectCreateStepTwo";
  private webhookHelp = [
    {
      text: "Текст помощи",
      image: "help1",
    },
    {
      text: "Тут тоже текст помощи",
      image: "help2",
    },
    {
      text: "Буквы литеры",
      image: "help3",
    },
    {
      text: "Лорем ипсум",
      image: "help4",
    },
  ];
  private webhookURL = "";
  private webhookInputActive = false;
  private webhookHelpShow = false;

  async mounted() {
    if (!this.projectId()) {
      this.$router.push({ name: "projectCreateStepOne" });
    } else {
      await this.$store.dispatch(StoreActionTypes.SET_PROJECTS_LIMITS);
    }
    this.$store.dispatch(
      StoreActionTypes.SET_DICTIONARIES_GET_DICTIONARIES_LIST
    );
  }

  copyWebhookUrl() {
    const copy = JSON.parse(JSON.stringify(this.webhookURL));
    this.webhookURL = "";
    return copy;
  }

  handleAmoBtnClick() {
    window.location.href = this.createAmoCrmLink();
  }

  handleBitrixBtnClick() {
    this.webhookInputActive = !this.webhookInputActive;
  }

  async handleBitrixNext() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_BITRIX_ADD,
      this.webhookURL
    );
  }

  createAmoCrmLink() {
    localStorage.setItem("integrationType", "amo");
    this.$store.commit(
      StoreMutationTypes.SET_PROJECT_INTEGRATION_INTEGRATION_TYPE,
      "amo"
    );
    const params = new URLSearchParams({
      state: this.projectId().toString(),
      name: this.projectName(),
      description: this.projectDesc(),
    });

    const redirectUri = process.env.VUE_APP_AMO_REDIRECT_URI
      ? process.env.VUE_APP_AMO_REDIRECT_URI
      : "";
    const secretsUri = process.env.VUE_APP_AMO_SECRETS_URI
      ? process.env.VUE_APP_AMO_SECRETS_URI
      : "";
    const logo = process.env.VUE_APP_AMO_LOGO
      ? process.env.VUE_APP_AMO_LOGO
      : "";

    return `https://www.amocrm.ru/oauth/?${params.toString()}&redirect_uri=${redirectUri}&secrets_uri=${secretsUri}&logo=${logo}&scopes[]=crm&scopes[]=notifications`;
  }

  webhookURLChange(value: string) {
    this.webhookURL = value;
  }

  projectName(): string {
    return this.$store.getters.getProjectIntegrationIntegration
      ? `${i18n.t("projects.amo.name")} ${
          (
            this.$store.getters
              .getProjectIntegrationIntegration as BaseEntityModel
          ).name
        }`
      : (i18n.t("projects.amo.name") as string);
  }

  projectId(): number {
    return this.$store.getters.getProjectIntegrationIntegration
      ? (
          this.$store.getters
            .getProjectIntegrationIntegration as BaseEntityModel
        ).id
      : null;
  }

  projectDesc(): string {
    return `${i18n.t("projects.amo.description")} ${this.projectName()}`;
  }

  get addIntegrationbBtnStatus(): boolean {
    return this.$store.getters.getProjectsLimits
      ? (this.$store.getters.getProjectsLimits as ProjectLimitsModel)
          .integrations.totalActive >=
          (this.$store.getters.getProjectsLimits as ProjectLimitsModel)
            .integrations.max
      : true;
  }

  get addIntegrationbBtnVariant(): ColorTheme {
    return this.addIntegrationbBtnStatus ? "secondary" : "primary";
  }

  get addIntegrationbBtnTitle(): string {
    return this.addIntegrationbBtnStatus
      ? (i18n.t("projects.create.limit") as string)
      : (i18n.t("projects.create.choose") as string);
  }
}
