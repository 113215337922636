
import Vue from "vue";
import Component from "vue-class-component";
import { IconDefinition, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { StoreActionTypes } from "@/store/types";
import { BaseEntityModel } from "@/services/types";

@Component
export default class ManagerReportsHeader extends Vue {
  private componentClass = "ManagerReportsHeader";
  private back: IconDefinition = faArrowLeft;

  get managerName() {
    return this.$store.getters.getReportsSelectedManager
      ? (this.$store.getters.getReportsSelectedManager as BaseEntityModel).name
      : "";
  }

  handleBackIconClick() {
    this.$store.dispatch(StoreActionTypes.SET_REPORTS_LIST_CLEAR);
    this.$router.push("/");
  }
}
